import { activeColor, checkActive } from '../func'

export default function CommandeNavIcon() {
  const isActive = checkActive('/gestion-de-stock/commande')
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="19"
      viewBox="0 0 17 19"
      fill="none"
    >
      <path
        d="M0.333496 13.7578V7.17127C0.333496 6.43965 1.0934 5.9557 1.75638 6.26509L7.05366 8.73715C7.40574 8.90145 7.63078 9.25481 7.63078 9.64334V16.583C7.63078 17.338 6.8263 17.8206 6.16019 17.4654L0.862908 14.6402C0.537044 14.4664 0.333496 14.1271 0.333496 13.7578Z"
        fill={isActive ? activeColor : 'white'}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.6931 7.55224L10.1533 8.73749C9.80121 8.90179 9.57617 9.25514 9.57617 9.64367V16.5834C9.57617 17.3383 10.3807 17.821 11.0468 17.4657L16.344 14.6405C16.6699 14.4667 16.8735 14.1275 16.8735 13.7581V7.1716C16.8735 6.43998 16.1136 5.95603 15.4506 6.26542L14.6931 6.6189V10.9346H12.6931V7.55224Z"
        fill={isActive ? activeColor : 'white'}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.24168 1.90968L1.62698 2.50457C0.790807 2.81264 0.744762 3.97798 1.55402 4.35106L8.18479 7.40793C8.45046 7.53041 8.75645 7.53041 9.02212 7.40793L12.0964 5.99067C10.585 5.31334 8.61751 4.39768 6.84686 3.57361L6.29189 3.31535C5.21917 2.81628 4.24993 2.36643 3.53367 2.0415C3.43042 1.99465 3.33297 1.95066 3.24168 1.90968ZM14.2469 4.99922L15.6529 4.35106C16.4621 3.97798 16.4161 2.81264 15.5799 2.50457L8.94916 0.0616568C8.72602 -0.0205524 8.48089 -0.0205522 8.25775 0.0616569L5.90265 0.929324C6.29188 1.10966 6.70625 1.30227 7.13553 1.50199L7.68638 1.75833C9.70436 2.69749 11.951 3.74309 13.4934 4.42316C13.7923 4.55493 14.0487 4.7543 14.2469 4.99922Z"
        fill={isActive ? activeColor : 'white'}
      />
    </svg>
  )
}
