export enum RootPage {
  TABLEAU_DE_BORD = 'tableau-de-bord',
  GESTION_DE_STOCK = 'gestion-de-stock',
  FACTURATION = 'facturation',
  INVENTAIRE = 'inventaire',
  CONFIGURATIONS = 'configurations',
  OTHER = 'OTHER',
}

export enum Page {
  TABLEAU_DE_BORD = 'tableau de bord',
  STOCK = 'Stock',
  ATTENDUS = 'Attendus',
  COMMANDES = 'Commandes',
  REFERENCE = 'reference',
  SOCIETE = 'societe',
  CLIENT = 'client',
  UTILISATEUR = 'utilisateur',
  HISTORY = 'history',
}

export enum PagingEnum {
  DEFAULT_PAGE_INDEX = 1,
  DEFAULT_PAGE_SIZE = 25,
  PAGE_SIZE_1 = 50,
  PAGE_SIZE_2 = 100,
}

export enum ModalName {
  STOCK = 'stock',
  ATTENDU = 'attendu',
  COMMANDE = 'commande',
  REFERENCE = 'reference',
  TRANSPORTEUR = 'transporteur',
  DESTINATAIRE = 'destinataire',
  FOURNISSEUR = 'fournisseur',
  HISTORY = 'history',
  OTHER = 'other',
}

export const Define = {
  ACCESS_TOKEN: 'access_token',
  USERNAME: 'username',
  USER_ID: 'user_id',
  CLIENT_ID: 'client_id',
  ROLE: 'user_role',
  MAIL: 'mail',
  CHOOSING_CLIENT: 'choosing_client',
  CHOOSING_CLIENT_CODENOM: 'choosing_client_codenom',
  CHOOSING_COMPANY: 'choosing_company',
  CHOOSING_COMPANY_CODENOM: 'choosing_company_codenom',
  CHOOSING_WAREHOUSE: 'choosing_warehouse',
  CHOOSING_WAREHOUSE_CODENOM: 'choosing_warehouse_codenom',
  CODE: 'code',
  MAX_CDN_LEVEL: 3,
  DOMAIN: '.bfast-vn.net',
  ONE_MINUTE: 90000,
  CHOOSING_CLIENT_CODENOM_MULTIPLE: 'choosing_client_codenom_multiple',
  CHOOSING_COMPANY_CODENOM_MULTIPLE: 'choosing_company_codenom_multiple',
  CHOOSING_WAREHOUSE_CODENOM_MULTIPLE: 'choosing_warehouse_codenom_multiple',
  IS_REQUESTING: 'is_requesting',
  SSO_COOKIE: 'SSO_COOKIE',
  SSO_COOKIE_REFRESH: 'SSO_COOKIE-X',
  ACCESS_TOKEN_EXP_AT: 'ACCESS_TOKEN_EXP_AT',
  REFRESH_TOKEN_EXP_AT: 'REFRESH_TOKEN_EXP_AT',
}
