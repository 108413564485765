import { activeColor, checkActive } from '../func'

export default function HistoryNavIcon() {
  const isActive = checkActive('/gestion-de-stock/history')

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="18"
      viewBox="0 0 15 18"
      fill="none"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.73862 8.99998L8.75489 8.98087L13.5443 3.28748C14.6387 1.98655 13.7138 7.33125e-07 12.0138 6.22618e-07L2.43499 0C0.73497 -1.10507e-07 -0.189872 1.98655 0.904501 3.28748L5.69391 8.98087L5.71032 9.00013L5.69405 9.01923L0.904639 14.7126C-0.189734 16.0136 0.735102 18.0001 2.43512 18.0001H12.0139C13.714 18.0001 14.6388 16.0136 13.5444 14.7126L8.75502 9.01924L8.73862 8.99998ZM7.96594 7.58194C7.56901 8.02008 6.88066 8.02008 6.48373 7.58194L3.84686 4.67127C3.26455 4.0285 3.72065 2.99988 4.58797 2.99988L9.8617 2.99988C10.729 2.99988 11.1851 4.0285 10.6028 4.67127L7.96594 7.58194ZM7.00445 11.6473C6.67308 11.6473 6.40444 11.916 6.40444 12.2474C6.40444 12.5787 6.67308 12.8474 7.00445 12.8474H7.41842C7.74979 12.8474 8.01843 12.5787 8.01843 12.2474C8.01843 11.916 7.74979 11.6473 7.41842 11.6473H7.00445ZM6.41798 10.2006C6.41798 9.86918 6.68662 9.60055 7.018 9.60055H7.43196C7.76334 9.60055 8.03197 9.86918 8.03197 10.2006C8.03197 10.5319 7.76334 10.8006 7.43196 10.8006H7.018C6.68662 10.8006 6.41798 10.5319 6.41798 10.2006ZM2.84486 15.1829L3.15872 14.7829C3.34829 14.5413 3.63837 14.4001 3.94548 14.4001H10.5045C10.8116 14.4001 11.1017 14.5413 11.2912 14.7829L11.6051 15.1829C12.12 15.8392 11.6524 16.8002 10.8183 16.8002H3.63162C2.79752 16.8002 2.33 15.8392 2.84486 15.1829Z"
        fill={isActive ? activeColor : 'white'}
      />
    </svg>
  )
}
