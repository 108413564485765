import './Nav.css'
import { Define, ModalName, Page, PagingEnum, RootPage } from '../types'
import LOGO from '../images/logo.png'
import StockNavIcon from '../icons/StockNavIcon'
import AttenduNavIcon from '../icons/AttenduNavIcon'
import CommandeNavIcon from '../icons/CommandeNavIcon'
import HistoryNavIcon from '../icons/HistoryNavIcon'
import Cookies from 'js-cookie'
import { checkActive } from '../func'

const baseURL = process.env.REACT_APP_API_ENDPOINT?.replace('/api/v1', '')
const ssoURL = `${baseURL}/?s_url=${window.location.hostname}${
  window.location.port ? `:${window.location.port}` : ''
}/tableau-de-bord`

/**
 * The @NavBar is a component display the navbar of the web. It includes Stock, Inventory, Configuration, Factory.
 * @author [anhnq]
 * @version 0.1
 * @since 16-Mar
 */

const menuStock = [
  {
    url: '/gestion-de-stock/stock',
    icon: <StockNavIcon />,
    name: 'Stock',
  },
  {
    url: '/gestion-de-stock/attendu',
    icon: <AttenduNavIcon />,
    name: 'Attendus',
  },
  {
    url: '/gestion-de-stock/commande',
    icon: <CommandeNavIcon />,
    name: 'Commande',
  },
  {
    url: '/gestion-de-stock/history',
    icon: <HistoryNavIcon />,
    name: 'Historique',
  },
]

const configurationsMenu = [
  {
    url: '/configurations/client',
    name: 'Clients',
  },
  {
    url: '/configurations/societe',
    name: 'Sociétés',
  },
  {
    url: '/configurations/utilisateur',
    name: 'Utilisateurs',
  },
  {
    url: '/configurations/reference',
    name: 'Références',
  },
  {
    url: '/wh/configuration',
    name: 'Entrepôts',
  },
]

export function NavBar(props: any) {
  const username = localStorage.getItem(Define.USERNAME)

  function logout() {
    localStorage.removeItem(Define.USERNAME)
    localStorage.removeItem(Define.CODE)
    localStorage.removeItem(Define.ROLE)
    Cookies.remove(Define.SSO_COOKIE)
    Cookies.remove(Define.SSO_COOKIE_REFRESH)
    Cookies.remove(Define.ACCESS_TOKEN_EXP_AT)
    Cookies.remove(Define.REFRESH_TOKEN_EXP_AT)
    window.location.href = ssoURL
  }

  const USERNAME_MAX_DIGITS = 20

  return (
    <nav className="nav-bar">
      <div className="icon">
        <a href="/tableau-de-bord">
          <img alt="logo" src={LOGO} />
        </a>
      </div>
      <div className="text">
        <a href="/tableau-de-bord">Tableau de bord</a>
      </div>
      <div className="text">
        <a>Gestion de Stock</a>
        <div className="nav-dropdown">
          {menuStock.map((item, index) => (
            <a
              key={index}
              className={`item ${checkActive(item.url) ? 'active' : ''}`}
              href={`${item.url}?page-index=${PagingEnum.DEFAULT_PAGE_INDEX}&page-size=${PagingEnum.DEFAULT_PAGE_SIZE}`}
              target="_self"
            >
              {item.icon}
              {item.name}
            </a>
          ))}
        </div>
      </div>
      <div className="text">
        <a target="_self">Facturation</a>
      </div>
      <div className="text">
        <a target="_self">Inventaire</a>
      </div>
      <div className="text">
        <a>Configurations</a>
        <div className="nav-dropdown">
          {configurationsMenu.map((item, index) => (
            <a
              key={index}
              className={`item ${checkActive(item.url) ? 'active' : ''}`}
              href={`${item.url}?page-index=${PagingEnum.DEFAULT_PAGE_INDEX}&page-size=${PagingEnum.DEFAULT_PAGE_SIZE}`}
              target="_self"
            >
              {item.name}
            </a>
          ))}
        </div>
      </div>
      {username ? (
        <div className="profile">
          <div className="text">
            <a>{username.substring(0, USERNAME_MAX_DIGITS)}</a>
            <div className="nav-dropdown" style={{ right: 10 }}>
              <a href="#" target="_self" className="item">
                Paramètres
              </a>
              <div
                className="item"
                onClick={logout}
                style={{ cursor: 'pointer' }}
              >
                Déconnexion
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </nav>
  )
}
